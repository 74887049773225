const resource = {
    en: {
        login: 'Login',
        email_address: 'Email Address',
        password: 'Password',
        login_button: 'Login',
        forgot_password: 'Forgot password?',
        logged_in: {
            heading: 'You are already logged in',
            home_button: 'Back to Home'
        }
    },
    de: {
        login: 'Anmelden',
        email_address: 'Email Adresse',
        password: 'Passwort',
        login_button: 'Anmelden',
        forgot_password: 'Passwort vergessen?',
        logged_in: {
            heading: 'Sie sind schon eingeloggt',
            home_button: 'Zurück zur Homepage'
        }
    }
}

export default resource;