<template>
    <div>
        <div class="authentication-card" v-if="user">
            <h1 class="authentication-heading" v-html="resource[lang].logged_in.heading" />
            <Button stretch rounded submit
                class="authentication-button" 
                color="primary" 
                :label="resource[lang].logged_in.home_button" 
                @click="$router.push('/')"
            />
        </div>

        <div v-if="!user">
            <form class="authentication-card" @submit.prevent="login">
                <h1 class="authentication-heading" v-html="resource[lang].login" />
                <AuthenticationInputText required
                    class="authentication-input" 
                    v-model="email" 
                    :placeholder="resource[lang].email_address"
                />
                <AuthenticationInputText required password
                    class="authentication-input" 
                    v-model="password" 
                    :placeholder="resource[lang].password" 
                />
                <Button stretch rounded submit
                    class="authentication-button" 
                    color="accent" 
                    :label="resource[lang].login_button" 
                />
            </form>
            
            <AuthenticationLink link="/forgot-password" :label="resource[lang].forgot_password" />
        </div>
    </div>
</template>

<script>
import { useQuasar } from 'quasar';
import { mapGetters } from 'vuex';

import resource from '@/assets/resources/login-resource'

import AuthenticationInputText from '@/components/basic/AuthenticationInputText'
import AuthenticationLink from '@/components/basic/AuthenticationLink'
import Button from '@/components/basic/Button'

import axios from 'axios';
import { promiseGetRequest, promisePostRequest } from '@/assets/js/axios-utilities'

export default {
    components: {
        AuthenticationInputText,
        AuthenticationLink,
        Button
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

       return {
           lang: lang,

           email: '',
           password: '',

           resource
       } 
    },
    computed: {
        ...mapGetters(['user']),
    },
    setup() {
        const quasar = useQuasar();
        
        return { 
            quasar
        }
    },
    methods: {
        // Request
        getMeRequest() {
            return promiseGetRequest('me');
        },
        loginRequest() {
            const request_body = {
                email: this.email,
                password: this.password
            }
            return promisePostRequest('login', request_body);
        },

        // Action
        async login() {
            this.$store.dispatch('loading', true);
            const response = await this.loginRequest();
            this.$store.dispatch('loading', false);

            if (response.status === 'success' || response.status === 200) {
                localStorage.setItem('jwt_token', response.data.access_token);
                const expire_time = new Date().getTime() + (response.data.expires_in * 1000);
                localStorage.setItem('token_expires', expire_time);
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt_token');

                this.$store.dispatch('loading', true);
                const next_response = await this.getMeRequest();
                this.$store.dispatch('loading', false);

                if (next_response.status === 'success' || next_response.status === 200) {
                    this.$store.dispatch('user', next_response.data);
                    this.$router.push('/dashboard/my-matrices');
                } else {
                    localStorage.removeItem('jwt_token');
                    localStorage.removeItem('token_expires');
                    this.quasar.notify({
                        position: 'bottom-left', 
                        message: 'A Problem occurred: Please consult Planungsmatrix team', 
                        color: 'negative'
                    });
                }
            } else {
                if (response.response.status == 422) {
                    for (let issue in response.response.data) {
                        this.quasar.notify({
                            position: 'bottom-left', 
                            message: `${issue}: ${response.response.data[issue]}`, 
                            color: 'negative'
                        });
                    }
                } else {
                    this.quasar.notify({
                        position: 'bottom-left', 
                        message: 'service not available', 
                        color: 'negative'
                    });
                }
            }
        }
    }
}
</script>